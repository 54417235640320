.arrow {
  width: 3vw;
  height: 3vw;
  border: 1vw solid;
  border-color: white transparent transparent white;
  transform: rotate(-135deg);
}

.arrowSliding {
  position: absolute;
  -webkit-animation: slide 4s linear infinite;
  animation: slide 4s linear infinite;
}
.delay2 {
  -webkit-animation-delay: 2s;
  animation-delay: 2s;
}

@-webkit-keyframes slide {
  0% {
    opacity: 0;
    transform: translateY(-10vw);
  }
  20% {
    opacity: 1;
    transform: translateY(-8vw);
  }
  80% {
    opacity: 1;
    transform: translateY(-2vw);
  }
  100% {
    opacity: 0;
    transform: translateY(0vw);
  }
}
@keyframes slide {
  0% {
    opacity: 0;
    transform: translateY(-10vw);
  }
  20% {
    opacity: 1;
    transform: translateY(-8vw);
  }
  80% {
    opacity: 1;
    transform: translateY(-2vw);
  }
  100% {
    opacity: 0;
    transform: translateY(0vw);
  }
}
